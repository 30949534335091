import { useState, useEffect, useCallback } from 'react';
import { Input } from '../../../common/Forms/Input/Input';
import { notify } from '../../../../assets/helpers/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as schemas from '../../../../assets/schemas/schemas';
import { ActionButton, AltButton } from '../../../common/Buttons/Buttons';
import { idCodes, planTypes, vehicleDocumentTypeOptions, externalvehicleDocumentTypeOptions, documentTypeEnum } from '../../../../assets/helpers/options';
import { statesService } from '../../../../services/statesService';
import { vehicleService } from '../../../../services/vehicleService';
import { armorTypesService } from '../../../../services/armorTypesService';
import { economicActivitiesService } from '../../../../services/economicActivitiesService';
import { membershipService } from '../../../../services/membershipService';
import { clearObject, capitalize, filterSatesMotoriderAV, removeBlankSpacesAndUppercase, validateIfOther, validateAndSet } from '../../../../assets/helpers/funciones';
import {
  SalesDropzone,
  FileComponent,
} from '../../../common/Dropzone/Dropzone';
import { useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { LoadSpinner } from '../../../common/LoadSpinner/LoadSpinner';
import './LandingForms.scss';
import authService from '../../../../services/authService';

export const CreateVehicleForm = (props) => {
  const { setcurentStep, curentStep, membership, vehicle, customer, selectedMembership } = props;

  const t = useTranslation();

  const [statesOptions, setStatesOptions] = useState([]);
  const [economicActivitiesOptions, setEconomicActivitiesOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [ownerCitiesOptions, setOwnerCitiesOptions] = useState([]);
  const [armorTypeOptions, setArmorTypeOptions] = useState([]);
  const [typesOptions, setTypesOptions] = useState([]);
  const [vehileError, setVehicleError] = useState(false);
  const [vehicleBrandOptions, setVehicleBrandOptions] = useState([]);
  const [vehicleModelOptions, setVehicleModelOptions] = useState([]);
  const [vehicleClassesOptions, setVehicleClassesOptions] = useState([]);
  const [municipalitiesOptions, setMunicipalitiesOptions] = useState([]);
  const [circulationCard, setcirculationCard] = useState('');
  const [isLoading, setIsloading] = useState(false);
  const [customerIsOwner, setcustomerIsOwner] = useState('SI');
  const { saleChannel } = useParams();


  const getDocumentsData = (file, documentType) => {

    const formData = new FormData();
    formData.append("file", file);
    formData.append("documentType", documentType);

    setIsloading(true)
    membershipService
      .getDocumentsData(formData)
      .then((response) => {
        if ([200, 201].includes(response.status)) {
          validateAndSet("plate", (response?.data?.plate), setValue, watch)
          validateAndSet("engineSerial", (response?.data?.engineSerial), setValue, watch)
          validateAndSet("bodySerial", (response?.data?.bodySerial), setValue, watch)
          validateAndSet("color", capitalize(response?.data?.color), setValue, watch)
          setIsloading(false);
        } else {
          setIsloading(false);
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => {
        setIsloading(false);
      });
  }


  const onDrop1 = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents

      };
      reader.readAsArrayBuffer(file);

      if (
        !['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(
          file.type
        )
      ) {
        notify('Agregar un formato valido de imagen', 'error');
        return null;
      }
      let maxWeight = 5;
      if (file.size * 0.000001 > maxWeight) {
        notify(`La imagen no debe superar los ${maxWeight} MB`, 'error');
      } else {
        setcirculationCard(file);
        getDocumentsData(file, watch("documentTypeImage"))
      }
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control,
    setError
  } = useForm({
    resolver: yupResolver(schemas.RegisterVehicleSchema(t)),
  });

  const handleRequired = (name) => {
    if (!watch(name)) {
      setError(name, { type: 'custom', message: 'Obligatorio' });
      return true
    } else return false
  }


  const onSubmit = handleSubmit(async (data) => {

    setVehicleError(false)


    let [filtered] = economicActivitiesOptions?.filter((c) => c?._id === data?.economicActivity)
    if (filtered?.name === 'Otro' && !watch('otherEconomicActivity') && membership?.rcv !== "N/A") {
      setError("otherEconomicActivity", { type: 'custom', message: 'Obligatorio' });
      return null
    }

    if (membership?.rcv !== "N/A") {

      let error = false;

      if (handleRequired("engineSerial")) {
        error = true
      }
      if (handleRequired("economicActivity")) {
        error = true
      }

      if (handleRequired("profession")) {
        error = true
      }
      if (error) {
        return null
      }
    }

    data.plate = data?.plate?.toUpperCase();
    data.bodySerial = data?.bodySerial?.toUpperCase();
    data.color = capitalize(data?.color);

    if (validateIfOther(watch, economicActivitiesOptions) && !watch("otherEconomicActivity" && membership?.rcv !== "N/A")) {
      setError("otherEconomicActivity", { type: 'custom', message: 'Obligatorio' });
      return null
    }


    if (!circulationCard && !saleChannel) {
      // notify('Adjunte su carnet de circulación', 'info');
      notify('Adjunte su título de propiedad del vehículo', 'error');
      setVehicleError(true)
      return null;
    }

    data = clearObject(data);
    let vehicle = { ...data };

    if (vehicle?.armorType === 'ninguno') {
      delete vehicle.armorType;
    }

    delete vehicle.documentTypeImage;

    let fileTopost = appendFile(vehicle);
    vehicle.brandName = capitalize(
      vehicleBrandOptions?.filter((c) => c?._id === vehicle?.brand)[0]?.name
    );
    vehicle.modelName = vehicleModelOptions?.filter(
      (c) => c?._id === vehicle?.model
    )[0]?.name;

    vehicle.stateName = capitalize(
      statesOptions?.filter((c) => c?._id === vehicle?.state)[0]?.name
    );
    vehicle.cityName = capitalize(
      citiesOptions?.filter((c) => c?._id === vehicle?.city)[0]?.name
    );
    vehicle.municipalityName = capitalize(
      municipalitiesOptions?.filter((c) => c?._id === vehicle?.municipality)[0]
        ?.name
    );

    if (!isLoading) {
      setIsloading(true);
      vehicleService
        .createVehicle(fileTopost)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            setIsloading(false);
            sessionStorage.setItem('vehicle', JSON.stringify(vehicle));
            setcurentStep(curentStep + 1);
          } else {
            setIsloading(false);
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const getCities = (state) => {
    statesService
      .getCities(state ? state : watch('state'))
      .then((response) => {
        if (response.status === 200) {
          setCitiesOptions(response?.data?.cities);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
    return true;
  };

  const getStates = () => {
    statesService
      .getStates()
      .then((response) => {
        if (response.status === 200) {

          let states = response?.data?.states

          if (selectedMembership?.code === planTypes?.MOTORIDERAV) {
            states = filterSatesMotoriderAV(states)
          }

          setStatesOptions(states);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };

  const getEconomicActivities = () => {
    economicActivitiesService
      .getEconomicActivities()
      .then((response) => {
        if (response.status === 200) {
          setEconomicActivitiesOptions(response?.data?.activities)
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };


  const getOwnerCities = (ownerState) => {
    statesService
      .getCities(ownerState ? ownerState : watch('ownerState'))
      .then((response) => {
        if (response.status === 200) {
          setOwnerCitiesOptions(response?.data?.cities);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
    return true;
  };

  const getMunicipalities = (city) => {
    statesService
      .getMunicipalities(city ? city : watch('city'))
      .then((response) => {
        if (response.status === 200) {
          setMunicipalitiesOptions(response?.data?.municipalities);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
    return true;
  };

  const appendFile = (rawData) => {
    let data = { ...rawData };

    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }


    if (circulationCard) {
      formData.append('circulation-card', circulationCard);
    }

    if (saleChannel) {
      formData.append('landing', 'Marqueting qr');
    }
    formData.append('customer', customer);
    formData.append('membership', membership?._id);
    formData.append(
      'armored',
      watch('armorType') && watch('armorType') !== 'ninguno' ? 'true' : 'false'
    );
    return formData;
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop: onDrop1 });

  const getSalesVehicleClasses = async () => {
    vehicleService
      .getSalesVehicleClasses()
      .then((response) => {
        if (response.status === 200) {
          setVehicleClassesOptions(response?.data?.classes);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
  };
  const getArmorTypes = () => {
    armorTypesService
      .getArmorTypes()
      .then((response) => {
        if (response.status === 200) {
          setArmorTypeOptions([
            { name: 'Ninguno', _id: 'ninguno' },
            ...response?.data,
          ]);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
  };

  const preloadValues = () => {
    let vehicleData = sessionStorage.getItem('vehicle');
    let customer = sessionStorage.getItem('customer');
    vehicleData = JSON.parse(vehicleData);
    customer = JSON.parse(customer);

    if (!authService?.getUser()) {
      setValue("documentTypeImage", documentTypeEnum?.CERTIFICATE_OR_TITLE)
    }

    if (customer) {
      setValue('profession', customer?.profession);
      setTimeout(() => {
        setValue('economicActivity', customer?.economicActivity);
      }, 1200);
    }


    if (vehicleData) {
      setValue('year', vehicle?.year);
      setValue('bodySerial', vehicleData?.bodySerial);
      setValue('color', vehicleData?.color);
      setValue('plate', vehicleData?.plate);
      setValue('bodySerial', vehicleData?.bodySerial);
      setValue('km', vehicleData?.km);

      Promise.allSettled([
        getVehicleTypes(vehicleData?.class),
        getSalesVehicleClasses(),
        getVehicleBrands(),
        getVehicleModels(vehicleData?.brand),
        getArmorTypes(),
        getStates(),
        getCities(vehicleData?.state),
        getMunicipalities(vehicleData?.city),
      ]).then((r) => {
        setTimeout(() => {
          // Address info
          setValue('state', vehicleData?.state);
          setValue('city', vehicleData?.city);
          setValue('municipality', vehicleData?.municipality);
          setValue('address', vehicleData?.address);
          setValue('addressReference', vehicleData?.addressReference);
          // Vehicle Info
          setValue('armorType', vehicleData?.armorType);
          setValue('class', vehicleData?.class);
          setValue('type', vehicleData?.type);
          setValue('brand', vehicleData?.brand);
          setValue('model', vehicleData?.model);
          setValue('type', vehicleData?.type);
        }, 500);
      });

      if (vehicleData?.customerIsOwner) {
        setValue('customerIsOwner', true);
        setcustomerIsOwner('SI');
      } else {
        setValue('customerIsOwner', false);
        setcustomerIsOwner('NO');
        setValue('ownerFirstName', vehicleData?.ownerFirstName);
        setValue('ownerLastName', vehicleData?.ownerLastName);
        setValue(
          'ownerIdentificationType',
          vehicleData?.ownerIdentificationType
        );
        setValue('ownerIdentification', vehicleData?.ownerIdentification);
        setValue('ownerAddress', vehicleData?.ownerAddress);

        Promise.allSettled([
          getStates(),
          getOwnerCities(vehicleData?.ownerState),
        ]).then((r) => {
          setTimeout(() => {
            setValue('ownerState', vehicleData?.ownerState);
            setValue('ownerCity', vehicleData?.ownerCity);
          }, 500);
        });
      }
    } else {
      setValue('year', vehicle?.year);
      setValue('customerIsOwner', true);
      Promise.allSettled([
        getVehicleTypes(vehicle?.class),
        getSalesVehicleClasses(),
        getArmorTypes(),
      ]).then((r) => {
        setTimeout(() => {
          setValue('armorType', vehicle?.armorType);
          setValue('class', vehicle?.class);
          setValue('type', vehicle?.type);
        }, 500);
      });
    }
  };


  const getVehicleTypes = (clase) => {
    vehicleService
      .getVehicleTypes({ class: clase })
      .then((response) => {
        if (response.status === 200) {
          setTypesOptions(response?.data?.types);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => { });
  };

  const getVehicleBrands = () => {
    vehicleService
      .getVehicleBrands()
      .then((response) => {
        if (response.status === 200) {
          setVehicleBrandOptions(response?.data?.vehicleBrands);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => { });
  };

  const getVehicleModels = (vehicleBrand) => {
    vehicleService.getVehicleModels(vehicleBrand).then((response) => {
      if (response.status === 200) {
        setVehicleModelOptions(response?.data?.models);
      } else {
        notify(`${response.message}`, 'error');
      }
    });
  };

  const handlecustomerIsOwner = (e) => {
    if (e?.target?.value === 'SI') {
      setValue('customerIsOwner', true);
      setcustomerIsOwner(e?.target?.value);
      clearTitularForm();
    }
    if (e?.target?.value === 'NO') {
      setValue('customerIsOwner', false);
      setcustomerIsOwner(e?.target?.value);
      clearTitularForm();
    }
  };

  const clearTitularForm = () => {
    setValue('ownerFirstName', '');
    setValue('ownerLastName', '');
    setValue('ownerState', '');
    setValue('ownerCity', '');
    setValue('ownerAddress', '');
    setValue('ownerIdentificationType', '');
    setValue('ownerIdentification', '');
  };

  useEffect(() => {
    getEconomicActivities()
    getStates();
    getVehicleBrands();
    preloadValues();
  }, []);

  let validateDisabled = () => {
    return false;
  };

  let validateIfshowForm = () => {
    if (saleChannel) {
      return true
    }
    if (watch("documentTypeImage")) { return true }
    else return false
  }
  return (
    <>
      <LoadSpinner show={isLoading} />
      <div className="landing-form-container">
        <h5>Información del Vehículo</h5>
        <p>(*) Campos obligatorios</p>
        <br />
        <form className='responsive-landing-form'>
          <div className="row">
            <div className="col-12">
              <div className="buttons-center-container">
                <Input
                  bootstrap={'col-6'}
                  label={'Tipo de Documento *'}
                  type={'select'}
                  name={'documentTypeImage'}
                  register={register}
                  placeholder={''}
                  options={authService?.getUser() ? vehicleDocumentTypeOptions : externalvehicleDocumentTypeOptions}
                  errors={errors?.documentTypeImage?.message}
                />
              </div>
              {validateIfshowForm() &&
                <>
                  {circulationCard ? (
                    <FileComponent
                      file={circulationCard}
                      remove={() => setcirculationCard('')}
                    />
                  ) : (
                    <SalesDropzone
                      // instructions={'Adjuntar carnet de circulación*'}
                      instructions={'Adjuntar título de propiedad del vehículo*'}
                      maxWeight={'(JPG, PNG, PDF, máx. 3MB)'}
                      getRootProps={getRootProps}
                      getInputProps={getInputProps}
                      message="Arrastrar o buscar Documento"
                      error={vehileError}
                    />
                  )}
                </>}
            </div>
            {validateIfshowForm() &&
              <>
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Clase *'}
                  type={'select'}
                  name={'class'}
                  register={register}
                  placeholder={''}
                  options={vehicleClassesOptions}
                  errors={errors?.class?.message}
                  disabled={true}
                />
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Tipo *'}
                  type={'select'}
                  name={'type'}
                  register={register}
                  placeholder={''}
                  options={typesOptions}
                  errors={errors?.type?.message}
                  disabled={validateDisabled()}
                />{' '}
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Placa *'}
                  type={'text'}
                  name={'plate'}
                  register={register}
                  placeholder={''}
                  errors={errors?.plate?.message}
                  onChange={(e) => removeBlankSpacesAndUppercase(watch('plate'), 'plate', setValue)}
                  disabled={validateDisabled()}
                />{' '}
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Marca *'}
                  type={'select'}
                  options={vehicleBrandOptions}
                  name={'brand'}
                  register={register}
                  placeholder={''}
                  errors={errors?.brand?.message}
                  disabled={validateDisabled()}
                  onChange={() => getVehicleModels(watch('brand'))}
                />{' '}
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Modelo *'}
                  type={'select'}
                  name={'model'}
                  register={register}
                  placeholder={''}
                  options={vehicleModelOptions}
                  errors={errors?.model?.message}
                />{' '}
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Color *'}
                  type={'text'}
                  name={'color'}
                  register={register}
                  placeholder={''}
                  errors={errors?.color?.message}
                />{' '}
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Año *'}
                  type={'number'}
                  name={'year'}
                  register={register}
                  placeholder={''}
                  control={control}
                  errors={errors?.year?.message}
                  disabled={true}
                />
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Serial Carrocería* (en el carnet de circulación)'}
                  type={'text'}
                  name={'bodySerial'}
                  register={register}
                  placeholder={''}
                  errors={errors?.bodySerial?.message}
                  onChange={(e) => removeBlankSpacesAndUppercase(watch('bodySerial'), "bodySerial", setValue)}
                  disabled={validateDisabled()}
                />{' '}
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Kilometraje del vehículo'}
                  type={'number'}
                  name={'km'}
                  register={register}
                  placeholder={''}
                  errors={errors?.km?.message}
                  disabled={validateDisabled()}
                  min={'0'}
                />{' '}
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Blindaje'}
                  type={'select'}
                  options={armorTypeOptions}
                  name={'armorType'}
                  register={register}
                  errors={errors?.armorType?.message}
                  disabled={true}
                />{' '}
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Estado *'}
                  type={'select'}
                  options={statesOptions}
                  name={'state'}
                  placeholder={'Seleccione'}
                  register={register}
                  errors={errors?.state?.message}
                  onChange={(e) => getCities(watch('state'))}
                  disabled={validateDisabled()}
                />{' '}
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Ciudad *'}
                  type={'select'}
                  options={citiesOptions}
                  name={'city'}
                  placeholder={'Seleccione'}
                  register={register}
                  errors={errors?.city?.message}
                  onChange={(e) => getMunicipalities()}
                  disabled={validateDisabled()}
                />{' '}
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Municipio *'}
                  type={'select'}
                  options={municipalitiesOptions}
                  name={'municipality'}
                  placeholder={'Seleccione'}
                  register={register}
                  errors={errors?.municipality?.message}
                  disabled={validateDisabled()}
                />{' '}
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Dirección*'}
                  type={'textarea'}
                  name={'address'}
                  placeholder={
                    'Ejemplo: Chacao Av. Blandín, con Calle Santa Teresa de Jesús, C.C. San Ignacio, Sotano 1'
                  }
                  register={register}
                  errors={errors?.address?.message}
                  disabled={validateDisabled()}
                />{' '}
                <Input
                  bootstrap={'col-12 col-md-6'}
                  label={'Punto de referencia *'}
                  type={'textarea'}
                  name={'addressReference'}
                  placeholder={
                    'Ejemplo: Edificio blanco frente a la bomba de gasolina'
                  }
                  register={register}
                  errors={errors?.addressReference?.message}
                  disabled={validateDisabled()}
                />{' '}


                {membership?.rcv !== "N/A" && <>
                  <h5 style={{ textAlign: "center", marginTop: "24px", marginBottom: "12px" }}>Información para RCV</h5>
                  <Input
                    bootstrap={'col-12 col-md-6'}
                    label={'Serial Motor *'}
                    type={'text'}
                    name={'engineSerial'}
                    register={register}
                    placeholder={''}
                    errors={errors?.engineSerial?.message}
                  />
                  <Input
                    bootstrap={'col-12 col-md-6'}
                    label={'Profesión/Ocupación *'}
                    type={'text'}
                    name={'profession'}
                    register={register}
                    placeholder={''}
                    errors={errors?.profession?.message}
                  />
                  <Input
                    bootstrap={'col-12 col-md-6'}
                    label={'Actividad Económica *'}
                    type={'select'}
                    options={economicActivitiesOptions}
                    name={'economicActivity'}
                    register={register}
                    placeholder={''}
                    errors={errors?.economicActivity?.message}
                  />
                  {validateIfOther(watch, economicActivitiesOptions) &&
                    <Input
                      bootstrap={'col-12 col-md-6'}
                      label={'Otra Actividad Económica *'}
                      type={'text'}
                      name={'otherEconomicActivity'}
                      register={register}
                      placeholder={''}
                      errors={errors?.otherEconomicActivity?.message}
                    />
                  }
                </>}

                <div className="col-12 col-md-6"></div>
                <div className="col-12 col-md-6 checbox-input-container">
                  <label htmlFor="">¿Eres el titular del vehículo?</label>
                  <div className="checbox-input-container-midle">
                    <div className="topping">
                      <input
                        type="checkbox"
                        id="topping"
                        name="customerIsOwner"
                        value={'SI'}
                        checked={customerIsOwner === 'SI'}
                        onChange={handlecustomerIsOwner}
                      />
                      Si
                    </div>{' '}
                    <div className="topping">
                      <input
                        type="checkbox"
                        id="topping"
                        name="customerIsOwner"
                        value={'NO'}
                        checked={customerIsOwner === 'NO'}
                        onChange={handlecustomerIsOwner}
                      />
                      No
                    </div>
                    <div className="result">
                      {/* Above checkbox is {isChecked ? 'checked' : 'un-checked'}. */}
                    </div>
                  </div>
                </div>
                {customerIsOwner === 'NO' ? (
                  <>
                    <Input
                      bootstrap={'col-12 col-md-6'}
                      label={'Nombre del titular *'}
                      type={'text'}
                      name={'ownerFirstName'}
                      register={register}
                      placeholder={''}
                      errors={errors?.ownerFirstName?.message}
                    />
                    <Input
                      bootstrap={'col-12 col-md-6'}
                      label={'Apellido del titular *'}
                      type={'text'}
                      name={'ownerLastName'}
                      register={register}
                      placeholder={''}
                      errors={errors?.ownerLastName?.message}
                    />{' '}
                    <Input
                      bootstrap={'col-4 col-sm-2 col-md-2'}
                      label={'C.I / RIF*'}
                      type={'select'}
                      name={'ownerIdentificationType'}
                      register={register}
                      placeholder={''}
                      options={idCodes}
                      errors={errors?.ownerIdentificationType?.message}
                    />{' '}
                    <Input
                      bootstrap={'col-8 col-sm-4 col-md-4'}
                      label={'del titular*'}
                      type={'number'}
                      name={'ownerIdentification'}
                      register={register}
                      placeholder={''}
                      errors={errors?.ownerIdentification?.message}
                    />{' '}
                    <Input
                      bootstrap={'col-12 col-md-6'}
                      label={'Estado del titular *'}
                      type={'select'}
                      options={statesOptions}
                      name={'ownerState'}
                      placeholder={'Seleccione'}
                      register={register}
                      errors={errors?.ownerState?.message}
                      onChange={(e) => getOwnerCities()}
                    />{' '}
                    <Input
                      bootstrap={'col-12 col-md-6'}
                      label={'Ciudad del titular *'}
                      type={'select'}
                      options={ownerCitiesOptions}
                      name={'ownerCity'}
                      placeholder={'Seleccione'}
                      register={register}
                      errors={errors?.ownerCity?.message}
                    />{' '}
                    <Input
                      bootstrap={'col-12 col-md-6'}
                      label={'Dirección del titular *'}
                      type={'textarea'}
                      name={'ownerAddress'}
                      placeholder={''}
                      register={register}
                      errors={errors?.ownerAddress?.message}
                    />
                  </>
                ) : null}
                <div className="col-12 col-md-3"></div>

              </>}       </div>

          <div
            style={{ margin: '24px 0px 48px 0px' }}
            className="buttons-center-container"
          >
            <AltButton
              label={'Regresar'}
              action={() => setcurentStep(curentStep - 1)}
            />
            <ActionButton label={'Siguiente'} action={onSubmit} />
          </div>
        </form>
      </div>
    </>
  );
};
