import { filterParams } from '../assets/helpers/funciones'
import { authInstance } from './axios'
let url = process.env.REACT_APP_API_URL

export const bancamigaService = {
  checkPayment: async (params) => {
    const response = await authInstance
      .get(
        `${url}/bancamiga/checkPayment?` +
          new URLSearchParams(filterParams(params))
      )
      .then(async (response) => {
        return response
      })
      .catch(function (error) {
        error.data = []
        return error
      })
    return response
  },
}
