import { useEffect, useState } from 'react';
import logo from '../../../images/logo.svg';
import { armorTypesService } from '../../../services/armorTypesService';
import { membershipCatalogService } from '../../../services/membershipCatalogService';
import { vehicleService } from '../../../services/vehicleService';
import { codeService } from '../../../services/codeService';
import { giftCardService } from '../../../services/giftCardService';
import { membershipService } from '../../../services/membershipService';
import { productService } from '../../../services/productService';
import { usersService } from '../../../services/usersService';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
  LandingRegisterStep1Form,
  DiscountForm,
} from '../../common/Forms/Form';
import { notify } from '../../../assets/helpers/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ActionButton, AltButton } from '../../common/Buttons/Buttons';
import {
  clearObject,
  filterParams,
  convertRCVNameToId,
  capitalize,
  formatCurrency,
} from '../../../assets/helpers/funciones';
import {
  MembershipButton,
  GiftcardMessage,
  DiscountMessage,
  WizzardProgressBar,
} from './LandingComponents/LandingComponents';
import { PersonalInfoForm } from './LandingForms/PersonalInfoForm';
import { CreateVehicleForm } from './LandingForms/CreateVehicleForm';
import { PlanPurchaseForm } from './LandingForms/PlanPurchaseForm';
import { SuccesPurchase } from './LandingForms/SuccesPurchase';
import { rateService } from '../../../services/rateService';
import { Header } from '../../../components/common/Header/Header';
import * as schemas from '../../../assets/schemas/schemas';
import { Input, FilterDatePicker } from '../../common/Forms/Input/Input';
import {
  codeTypeEnum,
  codeTypeOptions,
  fleetLoadRCVTypes,
  membershipCodes,
  LANDING,
  validateCodeEnum,
  KMServiceOptions,
  rcvEnum,
  vehicleWeightOptions,
  vehiclesEnum,
} from '../../../assets/helpers/options';
import { vintageYear } from '../../../assets/helpers/const';
import authService from '../../../services/authService';
import './Landing.scss';
import { currencyRateService } from '../../../services/currencyRateService';
import moment from 'moment';

export const Landing = () => {
  const { saleChannel } = useParams();
  const { t } = useTranslation();
  const [giftCard, setGiftCard] = useState('');
  const [curentStep, setcurentStep] = useState(1);
  const [discountCode, setDiscountCode] = useState('');
  const [membership, setMembership] = useState('');
  const [customer, setCustomer] = useState('');
  const [dollarRate, setDollarRate] = useState(1);
  const [dollarRateByDate, setDollarRateByDate] = useState(1);

  const [showAffiliation, setShowAlffiliation] = useState(false);
  const [rcvOptions, setRCVOptions] = useState([]);
  const [selectedMembership, setSelectedMembership] = useState('');
  const [sellerOptions, setSellerOptions] = useState([]);
  const [armorTypeOptions, setArmorTypeOptions] = useState([]);
  const [vehicleClassesOptions, setVehicleClassesOptions] = useState([]);
  const [membershipOptions, setMembershipOptions] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [noPlanAlert, setNoplanAlert] = useState(false);
  const [createdAt, setCreatedAt] = useState(new Date());

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schemas.landingRegisterStep1Schema(t)),
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    setValue: setValue2,
    reset: reset2,
    watch: watch2,
  } = useForm({
    resolver: yupResolver(schemas.validateGiftcardSchema(t)),
  });

  const onSubmit = handleSubmit(async data => {
    if (validateTruck() && !watch('truckWeight')) {
      notify('Indique el peso del vehículo', 'error');
      return null;
    }

    if (!selectedMembership) {
      notify('Seleccione un plan', 'error');
      setNoplanAlert(true);
      return null;
    }

    let dataToPost = {
      membershipId: selectedMembership?._id,
      giftcard: giftCard?._id,
      discountCode: discountCode?._id,
      rcv: convertRCVNameToId(data?.rcv, rcvOptions),
      km: watch('km'),
      truckWeight: watch('truckWeight'),
      useExtendedTow: watch('useExtendedTow'),
      saleChannel: saleChannel,
      priceInfo: {
        finalPriceBeforeDiscounts:
          selectedMembership?.finalPriceBeforeDiscounts,
        initialValue: selectedMembership?.originalPrice,
        yearSurcharge: selectedMembership?.yearSurcharge,
        armorTypeSurcharge: selectedMembership?.armorTypeSurcharge,
        classSurcharge: selectedMembership?.classSurcharge,
        rcvSurcharge: selectedMembership?.rcvSurcharge,
        discountByGiftcard: giftCard?.value ? giftCard?.value : 0,
        discountByCode: selectedMembership?.discountByCode,
        finalPrice: selectedMembership?.finalPrice,
        extendedTowSurcharge: selectedMembership?.extendedTowSurcharge,
      },
      seller: data?.seller,
      createdAt,
    };
    dataToPost = clearObject(dataToPost);

    if (!dataToPost?.rcv) {
      delete dataToPost?.rcv;
    }

    if (!isLoading) {
      setIsloading(true);
      membershipService
        .createMembership(dataToPost)
        .then(response => {
          if ([200, 201].includes(response?.status)) {
            setMembership(response?.data?.membership);
            if (authService.getUser()) {
              getCurrencyRateByDate();
            }
            setcurentStep(2);
            setIsloading(false);
          } else {
            notify(`${response?.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch(error => {
          setIsloading(false);
        });
    }
  });

  const getCurrencyRateByDate = () => {
    let params = {
      currency: 'USD',
      date: createdAt ? moment(createdAt).format('YYYY-MM-DD') : '',
      limit: 1,
    };

    currencyRateService
      .getCurrencyRates(params)
      .then(response => {
        if (response.status === 200) {
          let rate = response?.data?.result[0]?.rate;
          setDollarRateByDate(rate);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
    return true;
  };

  const handleRCVChange = () => {
    getAvailableMemberships(selectedMembership);
  };

  const changeVehicleData = () => {
    reset2();
    setGiftCard('');
    setDiscountCode('');
    getAvailableMemberships(selectedMembership);
  };

  const getUsers = async () => {
    let user = localStorage.getItem('user');

    if (user) {
      let params = {
        limit: 1000000,
        offset: 0,
        module: 'Ventas',
        status: 'Activo',
      };
      usersService
        .getUsers(params)
        .then(response => {
          if (response.status === 200) {
            let data = [];
            for (let i = 0; i < response?.data?.users?.length; i++) {
              data?.push({
                _id: response?.data?.users[i]?._id,
                name:
                  response?.data?.users[i]?.firstName +
                  '  ' +
                  response?.data?.users[i]?.lastName,
              });
            }

            setSellerOptions(data);

            let [selectedUser] = data?.filter(
              user => user?._id === authService?.getUser()?._id
            );
            setValue('seller', selectedUser?._id);
          } else {
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch(() => { });
    }
  };

  const onSubmit2 = handleSubmit2(async data => {
    setDiscountCode('');
    setGiftCard('');

    let params = {
      code: data?.code,
      type:
        data?.codeType === codeTypeEnum.DISCOUNT ? validateCodeEnum?.NEW : '',
    };

    if (data?.codeType === codeTypeEnum.DISCOUNT && !isLoading) {
      codeService
        .validateCode(filterParams(params))
        .then(response => {
          if ([200, 201].includes(response.status)) {
            setIsloading(false);
            setDiscountCode(response?.data);
          } else {
            if ([404].includes(response.status)) {
              notify(`Codigo de descuento no encontrado o expirado`, 'error');
              setIsloading(false);
              return null;
            }
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch(error => {
          setIsloading(false);
        });
    }
    if (data?.codeType === codeTypeEnum.GIFTCARD && !isLoading) {
      giftCardService
        .validateCode(filterParams(params))
        .then(response => {
          if ([200, 201].includes(response.status)) {
            setGiftCard(response?.data);
            setIsloading(false);
            getAvailableMemberships(selectedMembership);
          } else {
            if ([404].includes(response.status)) {
              notify(`Giftcard no encontrada o expirada`, 'error');
              setIsloading(false);
              return null;
            }

            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch(error => {
          setIsloading(false);
        });
    }
  });

  const getSalesVehicleClasses = () => {
    vehicleService
      .getSalesVehicleClasses()
      .then(response => {
        if (response.status === 200) {
          setVehicleClassesOptions(response?.data?.classes);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };
  const getArmorTypes = () => {
    armorTypesService
      .getArmorTypes()
      .then(response => {
        if (response.status === 200) {
          setArmorTypeOptions([
            { name: 'Ninguno', _id: 'ninguno' },
            ...response?.data,
          ]);
          setValue('armorType', 'ninguno');
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };

  const getDollarRate = () => {
    rateService
      .getDollarRate()
      .then(response => {
        if (response.status === 200) {
          setDollarRate(response?.data?.rate);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };

  const selectMembership = membership => {
    if (![membershipCodes?.GOLD]?.includes(membership?.code)) {
      setValue('km', '');
    }
    if ([membershipCodes?.GOLD]?.includes(membership?.code)) {
      setValue('km', 200);
    }
    setSelectedMembership(membership);
    setNoplanAlert(false);
    if (!watch('rcv')) {
      setValue('rcv', rcvEnum?.NA);
    }
    getRCVs(membership);
  };

  const getRCVs = membership => {
    if (membership?._id) {
      productService
        .getRCVs(membership?._id)
        .then(response => {
          if (response.status === 200) {
            let data = [
              { _id: fleetLoadRCVTypes?.N_A, name: fleetLoadRCVTypes?.N_A },
            ];

            for (let i = 0; i < response?.data?.length; i++) {
              data.push({
                _id: response?.data[i]?.name,
                name: response?.data[i]?.name,
                mongoId: response?.data[i]?._id,
                coverage: response?.data[i]?.configByVehicleClass,
              });
            }

            setRCVOptions([...data]);
            switch (membership?.code) {
              case membershipCodes?.RCV_ELITE:
                setValue(
                  'rcv',
                  data?.filter(r => r?.name === 'RCV INTERVIAL BÁSICO')[0]?.name
                );
                break;
              case membershipCodes?.RCV:
                setValue(
                  'rcv',
                  data?.filter(r => r?.name === 'RCV BÁSICO')[0]?.name
                );
                break;
              case membershipCodes?.FREE:
                setValue('rcv', data[0]?.name);
                break;
              default:
                getAvailableMemberships(membership);
                break;
            }
          } else {
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch(() => { });
    }
  };

  const getAvailableMemberships = selectedMembership => {
    if (watch('year')?.length < 4) {
      return null;
    }
    if (watch('year') && watch('year') > new Date().getFullYear() + 1) {
      notify('Ingrese un año valido', 'error');
      return null;
    }

    if (watch('year') && watch('year') < vintageYear) {
      notify(
        `El año del vehículo debe ser mayor a ${vintageYear - 1}`,
        'error'
      );
      return null;
    }

    let params = {
      year: watch('year'),
      class: watch('class'),
      truckWeight: watch('truckWeight'),
      armorType: watch('armorType') !== 'ninguno' ? watch('armorType') : '',
      discountCode: discountCode?._id,
      giftCard: giftCard?._id,
      rcv: watch('rcv'),
      landing: authService?.getUser() ? LANDING?.PRIVATE : LANDING?.PUBLIC,
      km: watch('km'),
      useExtendedTow: watch('useExtendedTow'),
    };

    if (watch('year') && watch('class')) {
      membershipCatalogService
        .getAvailableMemberships(filterParams(params))
        .then(response => {
          if (response.status === 200) {
            let data = [...response?.data];

            let validGiftcard = response?.data?.some(
              r => r?.discountByGiftCard
            );

            if (validGiftcard) {
              data = data?.filter(d => d?.discountByGiftCard);
            }

            setMembershipOptions(data);
            setSelectedMembership();
            // If there is a membership selected apply recharge
            let [option] = data?.filter(
              d => d?._id === selectedMembership?._id
            );
            if (option) {
              setSelectedMembership(option);
            }
          } else {
            notify(`${response.message}` || 'error', 'error');
          }
        })
        .catch(() => { });
    }
  };

  const handleSinceDateChange = e => {
    if (e > new Date()) {
      notify('Ingrese una fecha en un rago valido ', 'info');
    } else {
      setCreatedAt(e);
    }
  };

  const transformToUppercase = () => {
    setValue2('code', watch2('code').toUpperCase());
    getAvailableMemberships(selectedMembership);
  };

  const cancel = () => {
    setGiftCard('');
    setDiscountCode('');
    reset();
    reset2();
  };

  const clearStorageData = () => {
    sessionStorage.removeItem('personalData');
    sessionStorage.removeItem('payment');
    sessionStorage.removeItem('vehicle');
    sessionStorage.removeItem('customer');
  };

  const preloadPage = () => {
    if (authService.getUser()) {
      setShowAlffiliation(true);
    }
  };

  const checkSaleChannel = () => {
    if (saleChannel) {
      setShowAlffiliation(true);
    }
  };

  useEffect(() => {
    checkSaleChannel();
    getUsers();
    preloadPage();
    getDollarRate();
    getArmorTypes();
    getSalesVehicleClasses();
    getAvailableMemberships();
    clearStorageData();
  }, []);

  const validateTruck = () => {
    const [camion] = vehicleClassesOptions?.filter(option =>
      [vehiclesEnum?.CAMION]?.includes(option.name)
    );

    if (watch('class') === camion?._id) {
      return true;
    } else return false;
  };

  const rcvCoverage = _class => {
    let [selectedRCV] = rcvOptions?.filter(r => r?._id === watch('rcv'));

    if (
      selectedRCV &&
      [fleetLoadRCVTypes?.RCV_BASIC, fleetLoadRCVTypes?.RCV_AMPLIO]?.includes(
        selectedRCV?.name
      )
    ) {
      return (
        <h6>
          {capitalize(selectedRCV?.name)} - Coberturas :<br />
          <ul>
            <li style={{ marginTop: '6px' }}>
              Daños a cosas $
              {formatCurrency(
                selectedRCV?.coverage[
                  _class
                ]?.coverage?.propertyDamage?.toFixed(2)
              )}
            </li>
            <li style={{ marginTop: '6px' }}>
              Daños a terceros $
              {formatCurrency(
                selectedRCV?.coverage[
                  _class
                ]?.coverage?.personalInjury?.toFixed(2)
              )}
            </li>
          </ul>
        </h6>
      );
    }
  };
  useEffect(() => {
    getAvailableMemberships();
  }, [giftCard, discountCode]);

  let elColor = '';
  const pathname = window.location.pathname;
  if (pathname === '/new-membership' && authService?.getUserType()) {
    elColor = '#bce7f4';
  }
  return (
    <>
      <Header />
      <div style={{ backgroundColor: elColor, minHeight: '1000px' }}>
        <div className="container landing-container">
          <div className="row">
            <img src={logo} alt="logo" className="landing-logo" />
            <h1 style={{ marginBottom: '0px' }} className="title-component">
              Compra tu plan
            </h1>
            <div className="buttons-container wizzard-row">
              <WizzardProgressBar
                curentStep={curentStep}
              // setcurentStep={setcurentStep}
              />
            </div>
            {!showAffiliation && (
              <>
                <h1 className="sub-title-component">¿Qué deseas hacer?</h1>
                <div
                  style={{ marginTop: '24px', marginBottom: '70px' }}
                  className="buttons-center-container "
                >
                  <ActionButton
                    label={'Renovar mi plan'}
                    action={() =>
                      window.open(
                        process.env.REACT_APP_AUTORENOVATION_URL,
                        '_blank'
                      )
                    }
                  />
                  <ActionButton
                    label={'Afiliarme a un plan'}
                    action={() => setShowAlffiliation(true)}
                  />
                </div>
              </>
            )}

            {curentStep === 1 && showAffiliation && (
              <>
                <form action="">
                  <h1 className="sub-title-component">
                    1. Detalles del Vehículo
                  </h1>
                  {authService.getUser() && (
                    <div className="row">
                      <div className="col-3"></div>
                      <Input
                        bootstrap={'col-3'}
                        label={'Asesor '}
                        type={'select'}
                        name={'seller'}
                        options={sellerOptions}
                        register={register}
                        placeholder={''}
                        errors={errors?.seller?.message}
                      />{' '}
                      <FilterDatePicker
                        bootstrap={'col-3'}
                        label={'Fecha de creación'}
                        type={'date'}
                        name={'createdAt'}
                        value={createdAt}
                        onChange={handleSinceDateChange}
                        errors={errors?.createdAt?.message}
                      />{' '}
                    </div>
                  )}
                  <LandingRegisterStep1Form
                    register={register}
                    errors={errors}
                    watch={watch}
                    validateTruck={validateTruck}
                    options={{
                      armorTypeOptions: armorTypeOptions,
                      vehicleClassesOptions: vehicleClassesOptions,
                      vehicleWeightOptions: vehicleWeightOptions,
                    }}
                    onChange={{
                      changeVehicleData: changeVehicleData,
                    }}
                  />
                  {watch('year') && watch('class') && (
                    <>
                      <h1 className="sub-title-component">
                        2. Canjea aquí tu código de descuento o Giftcard
                      </h1>
                      <p style={{ textAlign: 'center' }}>
                        Si no posees ningún código, continua con la elección de
                        tu Plan.
                      </p>
                      <DiscountForm
                        register={register2}
                        errors={errors2}
                        options={{
                          codeTypeOptions: codeTypeOptions,
                        }}
                        onChange={{
                          getAvailableMemberships: getAvailableMemberships,
                          transformToUppercase: transformToUppercase,
                        }}
                        onSubmit={onSubmit2}
                      />
                      <div className="membership-buttons-container">
                        {giftCard && <GiftcardMessage giftCard={giftCard} />}
                        {discountCode && (
                          <DiscountMessage discountCode={discountCode} />
                        )}
                      </div>
                    </>
                  )}
                  {membershipOptions?.length > 0 && (
                    <>
                      <MembershipButtons
                        watch={watch}
                        membershipOptions={membershipOptions}
                        selectMembership={selectMembership}
                        selectedMembership={selectedMembership}
                        validateTruck={validateTruck}
                        noPlanAlert={noPlanAlert}
                      />
                    </>
                  )}
                  <div className="row">
                    <div className="col-12 col-md-4"></div>
                    {[membershipCodes?.GOLD]?.includes(
                      selectedMembership?.code
                    ) && (
                        <>
                          <div className="col-12 col-md-4">
                            <div className="row">
                              <Input
                                bootstrap={'col-12'}
                                label={'Km de Servicio de Grúa'}
                                type={'select'}
                                name={'km'}
                                options={KMServiceOptions}
                                placeholder={''}
                                register={register}
                                errors={errors?.km?.message}
                                onChange={handleRCVChange}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-4"></div>
                        </>
                      )}
                  </div>
                  {rcvOptions?.length > 0 &&
                    !['RCV', 'RCV_ELITE', 'FREE']?.includes(
                      selectedMembership?.code
                    ) && (
                      <div className="row">
                        <div className="col-12 col-md-4"></div>
                        <div className="col-12 col-md-4">
                          <div className="row">
                            <Input
                              bootstrap={'col-12'}
                              label={'Elige Tipo de RCV'}
                              type={'select'}
                              name={'rcv'}
                              options={rcvOptions}
                              placeholder={''}
                              register={register}
                              errors={errors?.rcv?.message}
                              onChange={handleRCVChange}
                            />
                            {rcvCoverage(watch('class'))}
                          </div>
                        </div>
                        <div className="col-12 col-md-4"></div>
                      </div>
                    )}
                  {[membershipCodes?.GOLD]?.includes(
                    selectedMembership?.code
                  ) && (
                      <>
                        {/* <div className="col-12 col-md-4">
                          <div className="row">
                            <Input
                              bootstrap={'col-12'}
                              label={'Km de Servicio de Grúa'}
                              type={'select'}
                              name={'km'}
                              options={KMServiceOptions}
                              placeholder={''}
                              register={register}
                              errors={errors?.km?.message}
                              onChange={handleRCVChange}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4"></div> */}
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <div
                            style={{
                              margin: '0 auto',
                            }}
                            className=""
                          >
                            <Input
                              bootstrap={
                                'col-12 text-center justify-content-center align-items-center'
                              }
                              subLabel={
                                'Añadir un (1) servicio de grúa ilimitado'
                              }
                              type={'checkbox'}
                              name={'useExtendedTow'}
                              placeholder={''}
                              register={register}
                              errors={errors?.km?.message}
                              onChange={handleRCVChange}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4"></div>
                      </>
                    )}

                  <div
                    style={{ marginTop: '24px', marginBottom: '70px' }}
                    className="buttons-center-container "
                  >
                    <AltButton label={'Cancelar'} action={cancel} />
                    <ActionButton label={'Siguiente'} action={onSubmit} />
                  </div>
                </form>
              </>
            )}
            {curentStep === 2 && (
              <PersonalInfoForm
                setcurentStep={setcurentStep}
                setCustomer={setCustomer}
                membership={membership}
                curentStep={curentStep}
              />
            )}
            {curentStep === 3 && (
              <CreateVehicleForm
                curentStep={curentStep}
                setcurentStep={setcurentStep}
                membership={membership}
                vehicle={getValues()}
                customer={customer}
                selectedMembership={selectedMembership}
              />
            )}
            {curentStep === 4 && (
              <PlanPurchaseForm
                curentStep={curentStep}
                dollarRateByDate={dollarRateByDate}
                setcurentStep={setcurentStep}
                showAffiliation={showAffiliation}
                membership={membership}
                selectedMembership={selectedMembership}
                vehicle={getValues()}
                customer={customer}
                dollarRate={dollarRate}
              />
            )}
            {curentStep === 5 && (
              <SuccesPurchase
                curentStep={curentStep}
                setcurentStep={setcurentStep}
                membership={membership}
                selectedMembership={selectedMembership}
                vehicle={getValues()}
                customer={customer}
                dollarRate={dollarRate}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export const MembershipButtons = props => {
  const {
    membershipOptions,
    selectMembership,
    selectedMembership,
    validateTruck,
    watch,
    noPlanAlert,
  } = props;

  if (validateTruck() && !watch('truckWeight')) {
    return (
      <h4
        style={{ textAlign: 'center', marginTop: '36px', marginBottom: '36px' }}
      >
        {' '}
        Indique el peso del camion
      </h4>
    );
  } else
    return (
      <>
        {' '}
        <h1 className="sub-title-component">3. Selecciona tu Plan</h1>
        <div className="membership-buttons-container">
          {membershipOptions?.map(m => (
            <>
              <MembershipButton
                membership={m}
                selectMembership={selectMembership}
                selectedMembership={selectedMembership}
                noPlanAlert={noPlanAlert}
              />
            </>
          ))}
        </div>
      </>
    );
};
