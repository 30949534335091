import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import logo from '../../../images/logo.svg';
import { membershipService } from '../../../services/membershipService';
import { notify } from '../../../assets/helpers/toast';
import {
  clearObject,
} from '../../../assets/helpers/funciones';
import {
  MembershipButton,
  WizzardProgressBar,
} from './QRLandingComponents/LandingFreeComponents.jsx';
import {
  FreePlanInfoComponent,
} from './QRLandingComponents/FreePlanInfo.jsx';
import { FreePersonalInfoForm } from './QRLandingForms/FreePersonalInfoForm';
import { FreeCreateVehicleForm } from './QRLandingForms/FreeCreateVehicleForm';
import { SuccesPurchase } from './QRLandingForms/SuccesPurchase';
import { rateService } from '../../../services/rateService';
import { Header } from '../../common/Header/Header';
import './QRLanding.scss';

export const QRLanding = () => {
  const { linkToken, saleChannel, affiliationType } = useParams();
  const [curentStep, setcurentStep] = useState(["F4"]?.includes(affiliationType) ? 1 : 2);
  const [membership, setMembership] = useState('');
  const [customer, setCustomer] = useState('');
  const [dollarRate, setDollarRate] = useState(1);
  const [isLoading, setIsloading] = useState(false);


  const createMembership = () => {
    let dataToPost = {
      saleChannel: saleChannel,
      membershipId: linkToken,
      priceInfo: {
        finalPriceBeforeDiscounts: 0,
        initialValue: 0,
        finalPrice: 0,
      },
    };
    dataToPost = clearObject(dataToPost);


    if (!isLoading) {
      setIsloading(true);
      membershipService
        .createMembership(dataToPost)
        .then((response) => {
          if ([200, 201].includes(response?.status)) {
            setMembership(response?.data?.membership);
            setcurentStep(2);
            setIsloading(false);
          } else {
            notify(`${response?.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch(() => {
          setIsloading(false);
        });
    }
  }



  const getDollarRate = () => {
    rateService
      .getDollarRate()
      .then((response) => {
        if (response.status === 200) {
          setDollarRate(response?.data?.rate);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };



  const clearStorageData = () => {
    sessionStorage.removeItem('personalData');
    sessionStorage.removeItem('payment');
    sessionStorage.removeItem('vehicle');
    sessionStorage.removeItem('identification');
  };


  useEffect(() => {
    getDollarRate();
    clearStorageData();

    if (["F2"]?.includes(affiliationType)) {
      createMembership()
    }
  }, []);


  useEffect(() => {
  }, []);

  return (
    <>
      <Header />
      <div style={{ minHeight: '1000px' }}>
        <div className="container landing-container">
          <div className="row">
            <img src={logo} alt="logo" className="landing-logo" />

            <div className="buttons-container">
              <WizzardProgressBar
                curentStep={curentStep}
                // setcurentStep={setcurentStep}
                affiliationType={affiliationType}
              />
            </div>
            {curentStep === 1 && (
              <>
                <div action="">
                  <>
                    <FreePlanInfoComponent action={createMembership} />
                  </>
                </div>
              </>
            )}
            {curentStep === 2 && (
              <FreePersonalInfoForm
                setcurentStep={setcurentStep}
                setCustomer={setCustomer}
                membership={membership}
                curentStep={curentStep}
              />
            )}
            {curentStep === 3 && (
              <FreeCreateVehicleForm
                curentStep={curentStep}
                setcurentStep={setcurentStep}
                membership={membership}
                customer={customer}
              />
            )}
            {curentStep === 4 && (
              <SuccesPurchase
                curentStep={curentStep}
                setcurentStep={setcurentStep}
                membership={membership}
                customer={customer}
                dollarRate={dollarRate}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};


export const MapAndPreselect = (props) => {
  const { memberships, selectMembership, selectedMembership } = props

  if (memberships?.length === 1) {
    selectMembership(memberships[0])
  }

  return (
    <>
      {memberships.map((m) => < MembershipButton
        membership={m}
        selectMembership={selectMembership}
        selectedMembership={selectedMembership}
      />)
      }
    </>
  )
}
