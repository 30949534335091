import membershipClasic from '../../../../images/membershipClasic.png';
import membershipGold from '../../../../images/membershipGold.svg';
import membershipGoldPLUS from '../../../../images/membershipGoldPLUS.svg';
import membershipFree from '../../../../images/membershipFree.png';
import membershipPlus from '../../../../images/membershipPlus.png';
import membershipMotorider from '../../../../images/membershipMotorider.svg';
import membershipBasico from '../../../../images/membershipBasico.svg';
import membershipRCVElite from '../../../../images/membershipRCVElite.svg';
import step1WizSelected from '../../../../images/step1WizSelected.svg';
import step2WizSelected from '../../../../images/step2WizSelected.svg';
import step3WizSelected from '../../../../images/step3WizSelected.svg';
import step5WizSelected from '../../../../images/step5WizSelected.svg';
import paymentMethodCash from '../../../../images/paymentMethodCash.svg';
import paymentMethodTDC from '../../../../images/paymentMethodTDC.svg';
import paymentMethodPaypal from '../../../../images/paymentMethodPaypal.svg';
import paymentMethodPagoMovil from '../../../../images/paymentMethodPagoMovil.svg';
import paymentMethodPOV from '../../../../images/paymentMethodPOV.svg';
import paymentMethodReserve from '../../../../images/paymentMethodReserve.svg';
import paymentMethodTransfer from '../../../../images/paymentMethodTransfer.svg';
import paymentMethodZelle from '../../../../images/paymentMethodZelle.svg';
import binancePayMethod from '../../../../images/binancePayMethod.svg';
import { paymentMethods, planTypes } from '../../../../assets/helpers/options';
import { returnIGTF } from '../../../../assets/helpers/funciones';

import canje from '../../../../images/canje.svg';
import './LandingFreeComponents.scss';

export const WizzardBar = (props) => {
  const { step, currentStep } = props;
  let uncompleted = '';

  if (currentStep < step) {
    uncompleted = 'uncompleted';
  }
  return <div className={`wizzard-bar wizzard-bar-${uncompleted}`}></div>;
};

export const WizzardButton = (props) => {
  const { src, step, currentStep, setcurentStep } = props;

  let uncompleted = '';

  if (currentStep < step) {
    uncompleted = 'uncompleted';
  }

  return (
    <img
      onClick={() => setcurentStep(step)}
      className={`wizzard-logo-button  wizzard-logo-button-${uncompleted}`}
      src={src}
    />
  );
};

export const WizzardProgressBar = (props) => {
  const { curentStep, setcurentStep, affiliationType } = props;

  return (
    <div className={`wizzard-container`}>
      {["F4"]?.includes(affiliationType) &&
        <>
          <WizzardButton
            src={step1WizSelected}
            step={1}
            currentStep={curentStep}
            setcurentStep={setcurentStep}
          />
          <WizzardBar step={2} currentStep={curentStep} /></>
      }
      <WizzardButton
        src={step2WizSelected}
        step={2}
        currentStep={curentStep}
        setcurentStep={setcurentStep}
      />
      <WizzardBar step={3} currentStep={curentStep} />
      <WizzardButton
        src={step3WizSelected}
        step={3}
        currentStep={curentStep}
        setcurentStep={setcurentStep}
      />
      <WizzardBar step={4} currentStep={curentStep} />
      <WizzardButton
        src={step5WizSelected}
        step={4}
        currentStep={curentStep}
        setcurentStep={setcurentStep}
      />
    </div>
  );
};

export const MembershipButton = (props) => {
  const { membership, selectedMembership, selectMembership } = props;
  let selected = '';

  if (membership?._id === selectedMembership?._id) {
    selected = 'selected';
  }

  return (
    <div
      onClick={() =>
        selectMembership(
          membership?._id === selectedMembership?._id ? '' : membership
        )
      }
      className={`plan-button-container` + ` plan-button-container-${selected}`}
    >
      <MembershipImage membership={membership} />
      {/* <h6>{membership?.code}</h6> */}
      <h6 style={{ marginTop: '12px' }}>
        {membership?.finalPriceBeforeDiscounts > membership?.finalPrice ? (
          <span style={{ color: 'gray', textDecoration: 'line-through' }}>
            {'   ' + membership?.finalPriceBeforeDiscounts + '$   '}
          </span>
        ) : (
          ''
        )}
        {membership?.finalPriceBeforeDiscounts > membership?.finalPrice ? (
          <span
            style={{ fontSize: '18px', marginLeft: '12px', color: '#f39158' }}
          >
            {'   ' + membership?.finalPrice + '$   '}
          </span>
        ) : (
          <>{membership?.finalPrice > 0 ? membership?.finalPrice + "$" : ""}</>
        )}
      </h6>
      {membership?.rcvSurcharge > 0 && (
        <h6 className="recharge-text ">
          Recargo de {membership?.rcvSurcharge}$ por RCV básico
        </h6>
      )}
      {membership?.armorTypeSurcharge > 0 && (
        <h6 className="recharge-text ">
          Recargo de {membership?.armorTypeSurcharge}$ por blindaje del vehiculo
        </h6>
      )}
      {membership?.yearSurcharge > 0 && (
        <h6 className="recharge-text ">
          Recargo de {membership?.yearSurcharge}$ antiguedad del vehiculo
        </h6>
      )}
    </div>
  );
};

export const MembershipImage = (props) => {
  const { membership } = props;
  let src = '';
  switch (membership?.code) {
    case 'CLASSIC':
      src = membershipClasic;
      break;
    case 'PLUS':
      src = membershipPlus;
      break;
    case 'GOLD':
      src = membershipGold;
      break;
    case 'GOLDENP':
      src = membershipGoldPLUS;
      break;
    case planTypes?.GOLDF4:
      src = membershipGold;
      break;
    case 'MOTORIDER':
      src = membershipMotorider;
      break;
    case 'RCV':
      src = membershipBasico;
      break;
    case 'RCV_ELITE':
      src = membershipRCVElite;
      break;
    case 'FREE':
      src = membershipFree;
      break;

    default:
  }

  if (membership?.code?.includes("PLUS")) {
    src = membershipPlus
  }

  return <img src={src} alt="plan" className="membership-button-logo" />;
};

export const GiftcardMessage = (props) => {
  const { giftCard } = props;
  return (
    <div className="gifcard-message-component">
      <img src={canje} alt="plan" />
      <h6>
        ¡Tarjeta válida! Plan: <b>{giftCard?.membership?.code + '  '}</b>
        Adquirida en <b>{giftCard?.channel}</b>
      </h6>
    </div>
  );
};

export const DiscountMessage = (props) => {
  const { discountCode } = props;
  return (
    <div className="gifcard-message-component">
      <img src={canje} alt="plan" />
      <h6>
        Tienes {discountCode?.discountNumber}
        <b>{discountCode?.discountType === 'Porcentaje' ? '%' : '$'}</b> de
        descuento en los planes{' '}
        <b>
          {discountCode?.appliesToMemberships?.map((m, i) => (
            <span key={m?.code}>
              {m?.code}
              {i < discountCode?.appliesToMemberships?.length - 1 ? ', ' : '.'}
            </span>
          ))}
        </b>
      </h6>
    </div>
  );
};

export const PaymentMethodCard = (props) => {
  const { method, openModal } = props;
  let igtf = false;

  let src = '';
  switch (method?.name) {
    case paymentMethods?.TDC:
      src = paymentMethodTDC;
      igtf = true;
      break;
    case paymentMethods?.STRIPE:
      src = paymentMethodTDC;
      igtf = true;

      break;
    case paymentMethods?.ZELLE:
      src = paymentMethodZelle;
      igtf = true;

      break;
    case paymentMethods?.TRANSFERENCIA_MERCANTIL:
      src = paymentMethodTransfer;
      break;

    case paymentMethods?.BANK_TRANSFER:
      src = paymentMethodTransfer;
      break;
    case paymentMethods?.TRANSFERENCIA_BANESCO:
      src = paymentMethodTransfer;
      break;
    case paymentMethods?.PAGO_MÓVIL:
      src = paymentMethodPagoMovil;
      break;
    case paymentMethods?.PAGO_MÓVIL_BANCAMIGA:
      src = paymentMethodPagoMovil;
      break;
    case paymentMethods?.PAYPAL:
      src = paymentMethodPaypal;
      igtf = true;

      break;
    case paymentMethods?.RESERVE:
      src = paymentMethodReserve;
      igtf = true;

      break;
    case paymentMethods?.CASH_USD:
      src = paymentMethodCash;
      igtf = true;
      break;
    case paymentMethods?.CASH:
      src = paymentMethodCash;
      igtf = true;
      break;
    case paymentMethods?.CASH_EUR:
      src = paymentMethodCash;
      igtf = true;

      break;
    case paymentMethods?.PUNTO_DE_VENTA:
      src = paymentMethodPOV;
      break;
    case paymentMethods?.BINANCE:
      src = binancePayMethod;
      igtf = true;

      break;
    default:
  }

  return (
    <div
      onClick={() => openModal()}
      className={`membership-button-container plan-button-container`}
    >
      <img src={src} alt="plan" className="payment-method-button-logo" />
      <h6 style={{ marginTop: '12px' }}>{method?.name}</h6>

      {igtf && (
        <div className="membership-button-iftg-label">
          <p>
            <b>IGTF</b>+{returnIGTF()}%
          </p>
        </div>
      )}
    </div>
  );
};
